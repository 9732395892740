import { isNotDefined } from '@sgme/fp';
import { NumberInput } from '@/components/booking/NumberInput';
import { ICellRendererParams } from 'ag-grid-community';
import { Deal } from '@/features/vacation/vacationModel';

export const swapPointCellRenderer = (
  // this is to override data type undefined inherited from agGRid
  // only case where Data property can be undefined is row grouping or loading infinite row models as per their doc
  // we don't use any of them
  params: ICellRendererParams<Deal>,
  isCurrent: boolean,
  convertMetaDataPayload: { vacationId: string; currency1: string; currency2: string },
) => {
  const { data, colDef } = params;

  if (isNotDefined(data)) {
    return;
  }

  if (isNotDefined(colDef)) {
    return;
  }

  const { vacationId, currency1, currency2 } = convertMetaDataPayload;
  const isReadOnly =
    !isCurrent || ['SENT', 'DONE', 'UNSUCCESSFUL', 'DELETED'].includes(data.status);

  if (isNotDefined(data)) {
    throw new Error('current feature implementation relies on data being always present');
  }

  const fieldName = colDef.field;

  return {
    component: NumberInput,
    params: {
      readonly: isReadOnly,
      fieldName,
      convertMetaDataPayload: { vacationId, currency1, currency2 },
    },
  };
};
