import './FormRates/FormRates.css';
import type { BidAsk, BlotterUrlParameters } from '@/features/vacation/vacationModel';
import { type PropsWithChildren, useRef, useState } from 'react';
import { formatDate } from 'date-fns';

import {
  type EditInput,
  getUpdatedInputsAll,
  type InputsKey,
  isBlotterReadOnly,
} from '@/features/blotter/blotterSlice';
import { useParams } from 'react-router-dom';
import { useConvertDealsMutation } from '@/api/deals.api';
import type { DealToConvert } from '@/components/booking/Blotter/RatesColumnsHeader';
import { type Placement, PopoverGlobalUpdate } from '@/components/booking/FormRates/PopoverGlobalUpdate';
import { useAppSelector } from '@/hooks/reduxHook';
import { useSgwtWidgets } from '@sgwt/sgwt-widgets-react';
import { getUpdatedInput } from '@/utils/updateUnput';
import type { DealsPayload } from '@/api/deals.models';
import { isDefined } from '@sgme/fp';

type FormRatesProps = {
  buttonClasses: string;
  popoverPlacement: Placement;
  dealsToConvert: DealToConvert[];
  isSwapPointColumn?: boolean;
};

export const FormRates = ({
  children,
  buttonClasses,
  popoverPlacement,
  dealsToConvert,
  isSwapPointColumn = false,
}: PropsWithChildren<FormRatesProps>) => {
  const [show, setShow] = useState(false);
  const { sgwtWebAnalytics } = useSgwtWidgets();

  const buttonRef = useRef(null);
  const blotterReadOnly = useAppSelector(isBlotterReadOnly);

  const { sessionId: vacationId, currency1, currency2 } = useParams() as BlotterUrlParameters;

  const updatedInputsAll = useAppSelector((state) => getUpdatedInputsAll(state));
  const [convertDeals] = useConvertDealsMutation();

  /**
   * When select a date and click on the "Apply" button
   * Dispatch mutation to edit right target deals. (exclude base on vacation deal data & updated input data)
   * If deal have update input, we use update value to know if we apply rate or not (cf proposed date issue)
   * @param param0
   * @param date
   */
  const applyAllRates = ({ bid, ask }: BidAsk, date: string) => {

    /**
     * First we get wich deals we will send for mutation (for swap need to check date on updated inputs)
     */
    const targetDealsForMutation = !isSwapPointColumn
      ? dealsToConvert // that means is for spot, so no need to check date
      : getForwardDealToConvert(dealsToConvert, updatedInputsAll, date) // For swap, need to check proposedDate in update inputs

    /**
     * Second, we format the payload
     */
    const preparedDealsPayload = prepareDealsPayload(
      targetDealsForMutation,
      updatedInputsAll,
      { bid, ask },
      isSwapPointColumn,
    );

    /**
     * Third, depending on which "applyAll" (spot|swap) we defined wich field we are updating
     */
    const inputsChanged = isSwapPointColumn
      ? (['swapPoint.bid', 'swapPoint.ask'] as InputsKey[])
      : (['spotRate.bid', 'spotRate.ask'] as InputsKey[]);

    /**
     * Bonus, we track this event
     */
    sgwtWebAnalytics?.trackEvent(
      'Bulk Convert',
      'User actions',
      isSwapPointColumn ? 'Swap points' : 'Spot rate',
    );

    /**
     * Fours, dispatch the mutation to convert
     */
    convertDeals({
      deals: preparedDealsPayload,
      inputsChanged,
      vacationId, currency1, currency2,
    });
  }

  const possibleDates = isSwapPointColumn ? getPossibleDates(dealsToConvert, updatedInputsAll) : [];

  return (
    <>
      <button
        type='button'
        data-e2e={`${isSwapPointColumn ? 'swap' : 'spot'}-${popoverPlacement === 'bottom' ? '' : 'footer-'}apply-global-rates-btn`}
        ref={buttonRef}
        className={buttonClasses}
        disabled={blotterReadOnly}
        onClick={() => setShow(!show)}
      >
        {children}
      </button>
      <PopoverGlobalUpdate
        buttonRef={buttonRef}
        placement={popoverPlacement}
        show={show}
        onHide={() => setShow(false)}
        onClick={applyAllRates}
        dateOptions={possibleDates ?? []}
      />
    </>
  );
};

/**
 * We merge both array
 * @param dealsToConvert
 * @param updatableDates
 * @returns
 */
function getPossibleDates(dealsToConvert: DealToConvert[], updatableDates: Record<string, EditInput>): string[] {
  const updatableDatesValue = Object.values(updatableDates)
    .filter(input => input.product !== "SPOT")
    .map(date => date['valueDate.proposedDate'])
    .filter(isDefined);

  const possibleDealsToConvertDate = dealsToConvert.reduce((dates: string[], deal) => {
    const updatedDeal = Object.entries(updatableDates).find(([dealId]) => dealId === deal.dealId);
    const dealDate = updatedDeal?.[1]?.['valueDate.proposedDate'] ?? deal.valueDate?.proposedDate ?? ""; // If date is update, i take, otherwise i take initial date.
    const isDatesExists = dates.some((date) => date === dealDate);

    if (!isDatesExists) {
      dates.push(formatDate(dealDate, 'yyyy-MM-dd'));
    }

    return dates;
  }, []);

  const uniqueDate = [...new Set([...updatableDatesValue, ...possibleDealsToConvertDate])];

  return uniqueDate ?? [];
}


/**
 * Format of updatedInput: Record<string, EditInput>
 * {
    "23055101": {
        "valueDate.proposedDate": "2024-11-29",
        "product": "FORWARD",
        "swapPoint.ask": 0,
        "swapPoint.bid": 0
      }
    }
 *
 */

const getForwardDealToConvert = (dealsToConvert: DealToConvert[], updatedInputsAll: Record<string, EditInput>, actualDate: string) => {
  const forwardDeals =  dealsToConvert.filter((deal) => {
    const updatedInputIds: string[] = Object.keys(updatedInputsAll);
    const isUpdatedDeals = updatedInputIds.includes(deal.dealId);
    const updatedDeal = Object.entries(updatedInputsAll).find(([dealId]) => dealId === deal.dealId);

    if (deal.product === "SPOT" && updatedDeal?.[1].product !== "FORWARD") { // If product is a spot and not update to a forward, i don't take it
      return false;
    }

    if (isUpdatedDeals && isDefined(updatedDeal?.[1]?.['valueDate.proposedDate'])) {
      // We have change the proposedDate for this deal, so we take this date to determine if we can target it or not.
      return updatedDeal[1]['valueDate.proposedDate'] === actualDate;
    }

    if (isDefined(deal.valueDate?.proposedDate)) {
      return formatDate(deal.valueDate?.proposedDate, 'yyyy-MM-dd') === formatDate(actualDate, 'yyyy-MM-dd')
    }
    return false;
  });

  return forwardDeals;
}


const prepareDealsPayload = (
  dealToConvert: DealToConvert[],
  updatedInputsAll: Record<string, EditInput>,
  { bid, ask }: BidAsk,
  isSwapPointColumn: boolean,
): DealsPayload[] => {
  return dealToConvert.map((deal) => {
    const updatedInput = getUpdatedInput(updatedInputsAll, deal.dealId);

    const dealSwapPoint = {
      bid: updatedInput?.['swapPoint.bid'] ?? deal.swapPoint?.bid,
      ask: updatedInput?.['swapPoint.ask'] ?? deal.swapPoint?.ask,
    };
    const dealSpotRate = {
      bid: updatedInput?.['spotRate.bid'] ?? deal.spotRate?.bid,
      ask: updatedInput?.['spotRate.ask'] ?? deal.spotRate?.ask,
    };

    const product = updatedInput?.product ?? deal.product;

    const swapPoint = isSwapPointColumn ? { bid, ask } : dealSwapPoint;
    const spotRate = !isSwapPointColumn ? { bid, ask } : dealSpotRate;

    return {
      dealId: deal.dealId,
      product,
      swapPoint,
      spotRate,
    };
  });
}
