import { useUpdateDealMutation } from '@/api/deals.api';
import type { Deal } from '@/features/vacation/vacationModel';
import { selectCurrentVacation } from '@/features/vacation/vacationSelectors';
import { useAppSelector } from '@/hooks/reduxHook';
import SgDatePicker from './DatePicker';
import { formatDate, formatISO } from 'date-fns';
import { DateTime as LuxonDatetime } from 'luxon';
import { getUpdatedInputsAll, updateInput } from '@/features/blotter/blotterSlice';
import { useDispatch } from 'react-redux';
import { getUpdatedInput } from '@/utils/updateUnput';
import { isDefined, isNotDefined } from '@sgme/fp';

const BLOTTER_DATE_FORMAT = 'dd LLL yyyy';

function ProposedDate({
  value,
  deal,
  isCurrent,
}: {
  value: string;
  deal: Deal;
  isCurrent: boolean;
}) {
  const [updateDeal] = useUpdateDealMutation();
  const dispatch = useDispatch();
  const updatedInputsAll = useAppSelector(getUpdatedInputsAll);
  const currentVacation = useAppSelector(selectCurrentVacation);
  const currentCurrencyPair = currentVacation?.currencyPairs.find((currencyPair) =>
    currencyPair.deals.some((dealForCurrencyPair) => dealForCurrencyPair.id === deal.id),
  );

  const theoreticalDate = LuxonDatetime.fromISO(deal.valueDate.theoreticalDate);
  const tradeDate = LuxonDatetime.fromISO(value);

  const isDifferentTheoreticalDate = !tradeDate.equals(theoreticalDate);

  const updatedInput = getUpdatedInput(updatedInputsAll, deal.id);

  const onChange = (value: string) => {
    dispatch(
      updateInput({
        dealId: deal.id,
        inputKey: 'valueDate.proposedDate',
        value: formatDate(value, 'yyyy-MM-dd'),
      }),
    );

    if (isNotDefined(currentCurrencyPair?.id)) {
      return;
    }

    updateDeal({
      currencyPair: `${currentCurrencyPair?.currency1}${currentCurrencyPair?.currency2}`,
      currencyPairId: currentCurrencyPair.id,
      dealId: deal.id,
      proposedValueDate: formatDate(value, 'yyyy-MM-dd'),
    }).then(({ data }) => {
      if (data?.product) {
        dispatch(
          updateInput({
            dealId: deal.id,
            inputKey: 'product',
            value: data?.product,
          })
        );

        if (data?.product === "FORWARD") {
          dispatch(updateInput({ dealId: deal.id, inputKey: 'swapPoint.ask', value: 0 }))
          dispatch(updateInput({ dealId: deal.id, inputKey: 'swapPoint.bid', value: 0 }))
        }
      }
    });
  };

  return (
    <div className={isDifferentTheoreticalDate ? 'text-warning fw-semibold' : ''}>
      {isCurrent && !["UNSUCCESSFUL", "SENT", "DONE", "DELETED"].includes(deal.status) ? (
        <SgDatePicker
          className={isDefined(updatedInput?.['valueDate.proposedDate']) ? 'text-info fw-semibold' : ''}
          e2e="proposedDate"
          onChange={onChange}
          selectionType="single"
          date={formatISO(updatedInput?.['valueDate.proposedDate'] ?? value)}
        />
      ) : (
        formatDate(value, BLOTTER_DATE_FORMAT)
      )}
    </div>
  );
}

export default ProposedDate;
