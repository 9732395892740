import type { RootState } from '@/state/store';
import { createSelector, createSlice, current, isAnyOf, type PayloadAction } from '@reduxjs/toolkit';
import { dealsApi } from '@/api/deals.api';
import type { BookingStatus, Deal, Product } from '../vacation/vacationModel';
import { isDefined, isNotDefined } from '@sgme/fp';
import { addErrorToShow } from '../errors/errorsSlice';
import { getUpdatedInput } from '@/utils/updateUnput';
import type { RefreshDealResponse, SaveDealResponse } from '@/api/deals.models';

export type InputsKey =
  | 'spotRate.bid'
  | 'spotRate.ask'
  | 'swapPoint.bid'
  | 'swapPoint.ask'
  | 'product'
  | "valueDate.proposedDate"
  | 'amount.sell'
  | 'amount.buy'
  | 'marketRate'
  | 'clientRate'

export type EditInput = {
  'spotRate.bid'?: number | null;
  'spotRate.ask'?: number | null;
  'swapPoint.bid'?: number | null;
  'swapPoint.ask'?: number | null;
  product?: 'SPOT' | 'FORWARD' | null;
  "valueDate.proposedDate"?: string | null;
  'amount.sell'?: number | null;
  'amount.buy'?: number | null;
  marketRate?: number | null;
  clientRate?: number | null;
};

type BlotterState = {
  inputs: Record<string, EditInput>;
  isReadOnly: boolean;
  convertedDealsId: string[];
  refreshedDealsId: string[];
  tabPosition: {
    rowId: null | string;
    columnId: null | InputsKey;
  };
  selectedStatusDeal: BookingStatus | null;
  selectedRows: Deal[];
};

const initialState: BlotterState = {
  inputs: {},
  isReadOnly: false,
  convertedDealsId: [],
  refreshedDealsId: [],
  tabPosition: {
    rowId: null,
    columnId: null,
  },
  selectedStatusDeal: null,
  selectedRows: [],
};

type UpdateInputAction = {
  dealId: string;
  inputKey: InputsKey;
  value: number | string | null;
};

type UpdateTabPositionAction = {
  rowId: string | null;
  columnId: InputsKey | null;
};

const blotterSlice = createSlice({
  name: 'blotter',
  initialState,
  reducers: {
    updateInput(state, { payload: { dealId, inputKey, value } }: PayloadAction<UpdateInputAction>) {
      const draft = state.inputs[dealId];
      state.inputs[dealId] = { ...draft, [inputKey]: value };
    },
    updateNextTabPosition(
      state,
      { payload: { rowId, columnId } }: PayloadAction<UpdateTabPositionAction>,
    ) {
      state.tabPosition.rowId = rowId;
      state.tabPosition.columnId = columnId;
    },
    clearBlotter() {
      // immer does not seem to allow a full override on the root state therefore we use
      // the classic way of returning a new whole state
      return initialState;
    },
    setSelectedStatusDeal(
      state,
      { payload: { dealStatus } }: PayloadAction<{ dealStatus: BookingStatus | null }>,
    ) {
      state.selectedStatusDeal = dealStatus;
    },
    setSelectedRows(state, { payload: { selectedRows } }: PayloadAction<{ selectedRows: Deal[] }>) {
      state.selectedRows = selectedRows;
    },
    resetSelectedRows(state) {
      state.selectedRows = initialState.selectedRows;
      state.selectedStatusDeal = initialState.selectedStatusDeal;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        // for any backend action we should make the UI readOnly while handling it
        dealsApi.endpoints.refreshDeal.matchPending,
        dealsApi.endpoints.saveDeals.matchPending,
        dealsApi.endpoints.convertDeals.matchPending,
        dealsApi.endpoints.discardDeals.matchPending,
        dealsApi.endpoints.sendDeals.matchPending,
      ),
      (state) => {
        state.isReadOnly = true;
      },
    );
    /**
     * Fallback to allow edit on the blotter when an error occurs
     */
    builder.addMatcher(
      isAnyOf(
        dealsApi.endpoints.refreshDeal.matchRejected,
        dealsApi.endpoints.saveDeals.matchRejected,
        dealsApi.endpoints.convertDeals.matchRejected,
        dealsApi.endpoints.discardDeals.matchRejected,
        dealsApi.endpoints.sendDeals.matchRejected,
      ),
      (state) => {
        state.isReadOnly = false;
      },
    );
    builder.addMatcher(
      isAnyOf(
        dealsApi.endpoints.saveDeals.matchFulfilled,
      ),
      (state, action) => {
        const savedDealsId = action.payload.map((deal) => {
          if (deal.status === "OK") {
            return deal.dealId
          }
        }).filter(isDefined);

        // remove ids that needs to be unstyled
        state.convertedDealsId = state.convertedDealsId.filter(
          (dealId) => !savedDealsId.includes(dealId),
        );
        // clear input that needs to be unstyled
        savedDealsId.forEach(
          (dealId) =>
          (state.inputs[dealId] = {
            ['spotRate.ask']: null,
            ['spotRate.bid']: null,
            ['swapPoint.ask']: null,
            ['swapPoint.bid']: null,
            ['product']: null,
            ['valueDate.proposedDate']: null,
            ['amount.sell']: null,
            ['amount.buy']: null,
            ['marketRate']: null,
            ['clientRate']: null,
          }),
        );
      },
    );
    builder.addMatcher(
      isAnyOf(
        dealsApi.endpoints.refreshDeal.matchFulfilled
      ),
      (state, action) => {
        const refreshDeals = action.payload.map((deal) => deal.dealId).filter(isDefined);

        state.refreshedDealsId = state.refreshedDealsId.filter(
          (dealId) => !refreshDeals.includes(dealId),
        );

        // clear input that needs to be unstyled
        refreshDeals.forEach(
          (dealId) => {
            if (isDefined(state.inputs) && isDefined(state.inputs[dealId])) {
              return (state.inputs[dealId] = {
                ['product']: state.inputs[dealId].product,
                ['valueDate.proposedDate']: state.inputs[dealId]['valueDate.proposedDate'],
                ['spotRate.ask']: null,
                ['spotRate.bid']: null,
                ['swapPoint.ask']: null,
                ['swapPoint.bid']: null,
                ['amount.sell']: null,
                ['amount.buy']: null,
                ['marketRate']: null,
                ['clientRate']: null,
              });
            }
          },
        );
      },
    );
    // To handle readonly on all field and prevent multiple action when it's not possible
    builder.addMatcher(
      isAnyOf(
        // when back end actions are success the blotter isn't read only any more
        dealsApi.endpoints.refreshDeal.matchFulfilled,
        dealsApi.endpoints.saveDeals.matchFulfilled,
        dealsApi.endpoints.convertDeals.matchFulfilled,
        dealsApi.endpoints.discardDeals.matchFulfilled,
        dealsApi.endpoints.sendDeals.matchFulfilled,
      ),
      // whenever the refresh and save action are fulfilled we should
      // make the blotter editable again and remove any UI style that applies to
      // user modification as we have now persisted the changes on the backend
      (state) => {
        state.isReadOnly = false;
        state.selectedStatusDeal = null;
        state.selectedRows = [];
      },
    );

    // whenever the convert request is fulfilled, we should
    // indicate to the user what data has been impacted
    builder.addMatcher(
      isAnyOf(dealsApi.endpoints?.convertDeals.matchFulfilled),
      (state, action) => {
        action.payload
          .filter((deal) => isNotDefined(deal.failureReasons) || deal.failureReasons.length === 0)
          .forEach((deal) => {
            if (!state.convertedDealsId.includes(deal.dealId)) {
              state.convertedDealsId.push(deal.dealId);
            }
          });
      },
    );

    // whenever the refresh request is fulfilled, we should
    // indicate to the user what data has been impacted
    builder.addMatcher(
      isAnyOf(dealsApi.endpoints?.refreshDeal.matchFulfilled),
      (state, action) => {
        action.payload
          .filter((deal) => isNotDefined(deal.failureReasons) || deal.failureReasons.length === 0)
          .forEach((deal) => {
            if (!state.refreshedDealsId.includes(deal.dealId)) {
              state.refreshedDealsId.push(deal.dealId);
            }
          });
      },
    );

    builder.addMatcher(isAnyOf(addErrorToShow), (state, action) => {
      const dealsInError = action.payload.stateBackendErrors.map((deal) => deal.dealId);
      // remove ids that needs to be unstyled
      state.convertedDealsId = state.convertedDealsId.filter(
        (dealId) => !dealsInError.includes(dealId),
      );

      state.refreshedDealsId = state.refreshedDealsId.filter(
        (dealId) => !dealsInError.includes(dealId),
      );
    });
  },
});
export const {
  updateInput,
  updateNextTabPosition,
  clearBlotter,
  setSelectedStatusDeal,
  setSelectedRows,
  resetSelectedRows,
} = blotterSlice.actions;

export default blotterSlice.reducer;

export const getUpdatedInputsAll = (state: RootState) => state.blotter.inputs;

export const getUpdatedInputsByDealId = (state: RootState, dealId: string) =>
  state.blotter.inputs[dealId];

export const getUpdatedInputByIdAndFieldName = (
  state: RootState,
  dealId: string,
  fieldName: InputsKey,
) => getUpdatedInputsByDealId(state, dealId)?.[fieldName] ?? null;

export const isBlotterReadOnly = (state: RootState) => state.blotter.isReadOnly;

export const getSelectedDealStatus = (state: RootState) => state.blotter.selectedStatusDeal;
export const getSelectedRows = (state: RootState) => state.blotter.selectedRows;

export const getUpdatedSelectedRows = createSelector(
  [getSelectedRows, getUpdatedInputsAll],
  (selectedRows, updatedInputsAll) => {

    return selectedRows.map((row) => {
      const updatedInput = getUpdatedInput(updatedInputsAll, row.id);

      return {
        ...row,
        product: (updatedInput?.['product'] as Product) ?? row.product
      }
    })
  },
);

const isSaveDeal = (deal: SaveDealResponse | RefreshDealResponse): deal is SaveDealResponse => deal.hasOwnProperty('status');