import DeselectButton from '@/components/booking/DealsBulkActionFooter/DeselectButton';
import SentDealsSelectionActionButton from '@/components/booking/DealsBulkActionFooter/SentDealsSelectionButton';
import { BookingStatus, Deal } from '@/features/vacation/vacationModel';
import { FC } from 'react';
import DeleteButton from '../DealsActions/DeleteButton';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '@/hooks/reduxHook';
import { getUserSesameId } from '@/features/user/userSlice';
import { DeletePayload } from '@/api/deals.models';

interface SentDealsBulkActionsFooterProps {
  selectAllSentDeals: (status: BookingStatus[]) => void;
  deselectAllSentDeals: () => void;
  selectButtonLabel: string;
  selectedOpenDeals: Deal[];
  currencyPairId: string;
}

const SentDealsBulkActionsFooter: FC<SentDealsBulkActionsFooterProps> = ({
  selectAllSentDeals,
  deselectAllSentDeals,
  selectButtonLabel,
  selectedOpenDeals,
  currencyPairId,
}) => {
  const { sessionId: vacationId } = useParams();
  const dealOwner = useAppSelector((state) => getUserSesameId(state) ?? '');

  const dealsToDelete: DeletePayload[] = selectedOpenDeals.map((openedDeal) => ({
    dealId: openedDeal.id,
    vacationId: vacationId!,
    dealOwner: dealOwner.toUpperCase(),
    product: openedDeal.product,
    currencyPairId,
  }));

  return (
    <div className="d-flex p-2 bg-info justify-content-between">
      <SentDealsSelectionActionButton
        selectAllSentDeals={selectAllSentDeals}
        selectButtonLabel={selectButtonLabel}
        isActive
      />
      <div>
        <DeselectButton onClick={deselectAllSentDeals} />
        <DeleteButton deals={dealsToDelete} overrideClassName="btn btn-outline-primary-alt py-1" isFromBulkAction />
      </div>
    </div>
  );
};

export default SentDealsBulkActionsFooter;
